import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className=" font20 semiBold animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 0px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#1e9bff")};
  width: 100%;
  padding: 20px 10px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  border-radius: 10px; /* Ensures the edges are rectangular */
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#111d57")};
    border: 0px solid #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }
`;


import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import CreditImage from "../../assets/img/no-credit.png";
// import QuotesIcon from "../../assets/svg/Quotes";
// import Dots from "../../assets/svg/Dots";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <Heading className="extraBold font60">
            This is what all you need.
          </Heading>
          <HeaderP className="font25 ">
            Here's some more about what you need.
          </HeaderP>
          <BtnWrapper className="button-container">
            <FullButton title="Get Started for Free"  style={{ width: '50%' }} />
            <img src={CreditImage} alt="no_credit" className="no-credit-image" />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9 }} />
          {/* <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Ralph Waldo Emerson</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper> */}
        </ImageWrapper>
        {/* <GreyDiv className="lightBg"></GreyDiv> */}
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 660px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  margin-top: 50px;  /* Adjust this value to lower the content */
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const Heading = styled.h1`
  max-width: 700px;  /* Restrict the width of the h1 */
  word-wrap: break-word;  /* Allow breaking long words */
  line-height: 1.2;  /* Adjust line spacing */
  margin: 0 auto;  /* Center the h1 within the container */
  padding-left: 0;  /* No left padding */
  padding-right: 0;  /* No right padding */
  display: block;  /* Ensure the heading is a block element */
  text-align: left;  /* Align text to the left */
  word-break: break-word;  /* Allow breaking words if needed */
  width: 100%;  /* Ensure it stretches to fit available space */
  
  /* Force at least four words per line */
  white-space: normal;
  overflow-wrap: break-word;
  hyphens: auto;  /* Allow automatic hyphenation if needed */
  
  /* Adjust font size to ensure proper word wrapping on all screen sizes */
  font-size: 3 rem;  /* You can adjust this value as per your design */
  
  @media only screen and (max-width: 960px) {
    font-size: 2rem;  /* Adjust for smaller screens */
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  align-items: center; /* Vertically align items in the middle */
  gap: 10px; /* Adjust gap between button and image */
  
  .no-credit-image {
    width: 300px;  /* Adjust the size of the image as needed */
    height: 50px;
  }
`;

// const GreyDiv = styled.div`
//   width: 30%;
//   height: 700px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 0;
//   @media (max-width: 960px) {
//     display: none;
//   }
// `;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
  width:100%;
`;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
// const DotsWrapper = styled.div`
//   position: absolute;
//   right: -100px;
//   bottom: 100px;
//   z-index: 2;
//   @media (max-width: 960px) {
//     right: 100px;
//   }
//   @media (max-width: 560px) {
//     display: none;
//   }
// `;


